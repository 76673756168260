<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="post-course-evaluation" :class="'btn btn_add_new'">{{ $t('elearning_tim.post_course_evaluation') }} {{ $t('globalTrans.list') }}</router-link>
            <b-button variant="primary" @click="pdfExport" class="mr-2">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                  <b-col>
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                          {{ $t('elearning_tim.post_course_evaluation') + ' ' + $t('globalTrans.details') }}
                      </list-report-head>
                  </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                    <tbody>
                      <tr>
                        <th style="width: 15%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">{{ $i18n.locale === 'bn' ? courseEvaluation.fiscal_year_bn : courseEvaluation.fiscal_year }}</td>
                        <th style="width: 15%"  class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $t('elearning_config.organization') }}</th>
                        <th style="width: 3%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">:</th>
                        <td style="width: 32%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $i18n.locale === 'bn' ? courseEvaluation.org_bn : courseEvaluation.org }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">{{ courseEvaluation.circular_memo_no }}</td>
                        <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.batch_no') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">
                          {{ getColumnName(allBatchList, courseEvaluation.batch_no)}}
                        </td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? courseEvaluation.training_type_bn : courseEvaluation.training_type }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? courseEvaluation.training_category_bn : courseEvaluation.training_category }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? courseEvaluation.training_title_bn : courseEvaluation.training_title }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-overlay :show="load">
                <b-row>
                  <div class="col-md-12" id="form">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.pre_course_evaluation')}} {{$t('globalTrans.list')}}</legend>
                        <div class="col-12">
                          <b-table-simple hover small caption-top responsive border v-if="formData.details.length > 0">
                            <b-thead class="thead">
                              <b-tr>
                                <b-th style="vertical-align: middle; width: 5%" class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                                <b-th style="vertical-align: middle; width: 15%" class="text-center">{{$t('globalTrans.name')}}</b-th>
                                <b-th style="vertical-align: middle; width: 15%" class="text-center">{{$t('globalTrans.mobile')}}</b-th>
                                <b-th style="vertical-align: middle; width: 20%;" class="text-left">{{$t('elearning_tim.posting_office')}}</b-th>
                                <b-th style="vertical-align: middle; width: 15%" class="text-right">{{$t('elearning_tim.obtained_marks')}}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tr v-for="(trainData, index) in formData.details" :key="index">
                              <b-td class="text-center">{{$n(index+1)}}</b-td>
                              <b-td class="text-center">
                                {{ ($i18n.locale==='bn') ? trainData.name_bn : trainData.name }}
                              </b-td>
                              <b-td class="text-center">
                                {{ trainData.mobile }}
                              </b-td>
                              <b-td class="text-left">
                                <slot v-if="trainData.training_status === 1">
                                  {{ ($i18n.locale==='bn') ? trainData.designation_name_bn : trainData.designation_name }}
                                </slot>
                                <slot v-else>
                                  {{ trainData.designation }}
                                </slot>
                                {{ (($i18n.locale==='bn') ? trainData.office_name_bn : trainData.office_name) + ', ' + (($i18n.locale==='bn') ? trainData.org_name_bn : trainData.org_name) }}
                              </b-td>
                              <b-td class="text-right">
                                {{ trainData.obtained_mark }}
                              </b-td>
                            </b-tr>
                          </b-table-simple>
                          <template v-if="formData.details.length === 0">
                            <br/>
                            <br/>
                            <h5 class="text-center text-danger" style="height: 80px">{{$t('globalTrans.noDataFound')}}</h5>
                          </template>
                        </div>
                    </fieldset>
                  </div>
                </b-row>
              </b-overlay>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { manualCourseEvaluation, circularPublication } from '../../api/routes'

export default {
    components: {
        ListReportHead
    },
    created () {
        if (this.$route.query.circilarMemoNo) {
            this.circularPublicationAdd(this.$route.query.circilarMemoNo)
        }
        // this.getMaterils(this.courseEvaluation.training_title_id)
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        details: []
      },
      load: false,
      officeTypeList: [],
      allreadyApplicant: [],
      courseEvaluation: [],
      materials: [],
      courses: [],
      items: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async circularPublicationAdd (getCircularMemoNo) {
      if (getCircularMemoNo) {
        this.load = true
        const params = {
          circular_memo_no: getCircularMemoNo,
          table: 'iab_circular_publications'
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
        if (result.success) {
          this.load = false
          this.getCircularMemoNo()
        }
      }
    },
    async getCircularMemoNo () {
      const circularMemoNo = {
        circular_memo_no: this.$route.query.circilarMemoNo,
        batch_no: this.$route.query.batch_no,
        type: 'post'
      }
      this.load = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, manualCourseEvaluation, circularMemoNo)
      if (!result.success) {
        this.allreadyApplicant = []
        this.formData.details = []
      } else {
        this.allreadyApplicant = result.allreadyApplicant
        this.getCustomDataZeroList(result.allreadyApplicant[0])
        const datas = result.data.map(item => {
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org_name = orgObj.text_en
            orgData.org_name_bn = orgObj.text_bn
          } else {
            orgData.org_name = ''
            orgData.org_name_bn = ''
          }
          const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.professional_office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office_name = officeObj.text_en
            officeData.office_name_bn = officeObj.text_bn
          } else {
            officeData.office_name = ''
            officeData.office_name_bn = ''
          }
          const allreadyCourseObj = this.allreadyApplicant.find(allready => allready.training_application_id === parseInt(item.id))
          desigData.total_mark = 0
          desigData.obtained_mark = typeof allreadyCourseObj !== 'undefined' ? allreadyCourseObj.obtained_mark : 0
          return Object.assign({}, item, desigData, orgData, officeData)
        })
        this.formData.details = datas
      }
      this.load = false
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.post_course_evaluation') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.courseEvaluation, this, this.formData, this.courses)
    },
    getCustomDataZeroList (item) {
      this.courseEvaluation = item
      const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
      if (typeof orgObj !== 'undefined') {
        this.courseEvaluation.org = orgObj.text_en
        this.courseEvaluation.org_bn = orgObj.text_bn
      } else {
        this.courseEvaluation.org = ''
        this.courseEvaluation.org_bn = ''
      }

      const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
      if (typeof officeObj !== 'undefined') {
        this.courseEvaluation.office = officeObj.text_en
        this.courseEvaluation.office_bn = officeObj.text_bn
      } else {
        this.courseEvaluation.office = ''
        this.courseEvaluation.office_bn = ''
      }

      const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
      if (typeof fiscalYearObj !== 'undefined') {
        this.courseEvaluation.fiscal_year = fiscalYearObj.text_en
        this.courseEvaluation.fiscal_year_bn = fiscalYearObj.text_bn
      } else {
        this.courseEvaluation.fiscal_year = ''
        this.courseEvaluation.fiscal_year_bn = ''
      }
      const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
      if (typeof trainingTitleObj !== 'undefined') {
        this.courseEvaluation.training_title = trainingTitleObj.text_en
        this.courseEvaluation.training_title_bn = trainingTitleObj.text_bn
      } else {
        this.courseEvaluation.training_title = ''
        this.courseEvaluation.training_title_bn = ''
      }
      const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
      if (typeof OfficeTypeListObj !== 'undefined') {
        this.courseEvaluation.office_type = OfficeTypeListObj.text_en
        this.courseEvaluation.office_type_bn = OfficeTypeListObj.text_bn
      } else {
        this.courseEvaluation.office_type = ''
        this.courseEvaluation.office_type_bn = ''
      }
      const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
      if (typeof trainingTypeObj !== 'undefined') {
        this.courseEvaluation.training_type = trainingTypeObj.text_en
        this.courseEvaluation.training_type_bn = trainingTypeObj.text_bn
      } else {
        this.courseEvaluation.training_type = ''
        this.courseEvaluation.training_type_bn = ''
      }
      const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
      if (typeof trainingCategoryObj !== 'undefined') {
        this.courseEvaluation.training_category = trainingCategoryObj.text_en
        this.courseEvaluation.training_category_bn = trainingCategoryObj.text_bn
      } else {
        this.courseEvaluation.training_category = ''
        this.courseEvaluation.training_category_bn = ''
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
